<template>
  <Breadcrumb />
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <form class="row g-3 form-db-setting-new" novalidate>
            <div v-if="errors.length > 0" class="alert alert-warning">
              <ul class="mb-0">
                <li v-for="error in errors" v-bind:key="error">
                  {{ error }}
                </li>
              </ul>
            </div>
            <div v-if="isSuccess" class="alert alert-success">
              {{
                $t(
                  "Messages.SuccessMessage",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </div>
            <div class="col-md-6">
              <label for="Name" class="form-label required"
                >{{
                  $t(
                    "Localizations.Name",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="Name"
                @keyup="formulaNameKeyup()"
                v-model="localizationKeyData.Name"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>

            <div class="col-md-6">
              <label for="FormulaName" class="form-label required">{{
                $t(
                  "Localizations.FormulaName",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                type="text"
                class="form-control"
                id="FormulaName"
                @keyup="formulaNameKeyup()"
                v-model="localizationKeyData.FormulaName"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-6">
              <label for="Type" class="form-label required">{{
                $t(
                  "Localizations.KeyType",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <FormSelect
                type="SelectWithLocalData"
                :selectedData="selectedLocalizationKeyType"
                :allowEmpty="false"
                :data="localizationKeyTypeData"
                @onChange="onChangeForLocalizationKeyType"
              />
            </div>
            <div class="col-md-6">
              <label for="ParentPublicId" class="form-label required">{{
                $t(
                  "Localizations.ParentPublicId",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                type="text"
                class="form-control"
                id="ParentPublicId"
                v-model="localizationKeyData.KeyItemParentPublicId"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-12">
              <div class="form-check">
                <label for="IsActive" class="form-label">{{
                  $t(
                    "LocalizationLanguages.IsActive",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsActive"
                  v-model="localizationKeyData.IsActive"
                />
              </div>
            </div>
            <ActionButtons
              v-if="this.$store.getters._isSuperAdmin"
              @methodSave="onSubmit('0', 'btn-save')"
              @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
              @clear="onClear()"
              :isNewRecord="true"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "LocalizationNew",
  components: {},
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save, .btn-save-and-new, .btn-clear",
      errors: [],
      localizationKeyData: {
        Name: "",
        FormulaName: "",
        LocalizationKeyItemTypeId: 1,
        LocalizationKeyItemType: "CustomObject",
        KeyItemParentPublicId: "",
        IsActive: true,
      },
      isSuccess: false,
      localizationKeyTypeData: [
        {
          key: 1,
          value: this.$t(
            "LocalizationKeyTypes.CustomObject",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 11,
          value: this.$t(
            "LocalizationKeyTypes.CustomObjectPluralName",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "LocalizationKeyTypes.Field",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "LocalizationKeyTypes.ViewFilter",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 4,
          value: this.$t(
            "LocalizationKeyTypes.BusinessRuleButton",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 5,
          value: this.$t(
            "LocalizationKeyTypes.PageLayoutPanel",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 6,
          value: this.$t(
            "LocalizationKeyTypes.Menu",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 7,
          value: this.$t(
            "LocalizationKeyTypes.MenuItem",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 8,
          value: this.$t(
            "LocalizationKeyTypes.SelectListItem",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 9,
          value: this.$t(
            "LocalizationKeyTypes.ObjectRelation",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 10,
          value: this.$t(
            "LocalizationKeyTypes.Button",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 12,
          value: this.$t(
            "LocalizationKeyTypes.FormTemplate",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 13,
          value: this.$t(
            "LocalizationKeyTypes.CalendarView",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 14,
          value: this.$t(
            "LocalizationKeyTypes.TreeView",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 15,
          value: this.$t(
            "LocalizationKeyTypes.KanbanView",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 16,
          value: this.$t(
            "LocalizationKeyTypes.MapView",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedLocalizationKeyType: [
        {
          key: 1,
          value: this.$t(
            "LocalizationKeyTypes.CustomObject",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
    };
  },
  methods: {
    onChangeForLocalizationKeyType: function (selected) {
      this.localizationKeyData.LocalizationKeyItemType = selected.value;
      this.localizationKeyData.LocalizationKeyItemTypeId = selected.key;
    },
    formulaNameKeyup: function () {
      if (String.isNullOrWhiteSpace(this.localizationKeyData.Name)) {
        this.localizationKeyData.FormulaName = "";
        return;
      }
      this.localizationKeyData.FormulaName = String.replaceAlphanumeric(
        this.localizationKeyData.Name,
        "_"
      ).toUpperCase();

      //this.localizationKeyData.modelCamelCaseName = this.createModelCamelCaseName();
    },
    onClear() {
      this.keyItemTypeId = 1;
      $(".form-db-setting-new").removeClass("was-validated");
    },
    onSubmit(submitType, buttonName) {
      var form = $(".form-db-setting-new");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Brs-LocalizationNew", { ...this.localizationKeyData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            if (submitType == 0) {
              this.$router.push("/Localization/List");
            } else {
              this.isSuccess = true;
              setTimeout(
                () => (this.isSuccess = false),
                this.$appSaveAndNewMessageDuration
              );
              this.onClear();
              firstSpan.show();
              loadingBlock.hide();
              disabledButtons.prop("disabled", false);
              form.removeClass("was-validated");
            }
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
};
</script>
